// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-7-step-slider-blog-js": () => import("./../../../src/pages/7step_slider_blog.js" /* webpackChunkName: "component---src-pages-7-step-slider-blog-js" */),
  "component---src-pages-fonts-tester-js": () => import("./../../../src/pages/fonts-tester.js" /* webpackChunkName: "component---src-pages-fonts-tester-js" */),
  "component---src-pages-ft-js": () => import("./../../../src/pages/ft.js" /* webpackChunkName: "component---src-pages-ft-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lastlongerinbed-slider-blog-js": () => import("./../../../src/pages/lastlongerinbed_slider_blog.js" /* webpackChunkName: "component---src-pages-lastlongerinbed-slider-blog-js" */),
  "component---src-pages-lp-js": () => import("./../../../src/pages/lp.js" /* webpackChunkName: "component---src-pages-lp-js" */),
  "component---src-pages-mealplan-slider-blog-js": () => import("./../../../src/pages/mealplan_slider_blog.js" /* webpackChunkName: "component---src-pages-mealplan-slider-blog-js" */),
  "component---src-pages-pages-sitemap-dynamic-js": () => import("./../../../src/pages/pages-sitemap.dynamic.js" /* webpackChunkName: "component---src-pages-pages-sitemap-dynamic-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-q-1-coach-supp-js": () => import("./../../../src/pages/q1/coach-supp.js" /* webpackChunkName: "component---src-pages-q-1-coach-supp-js" */),
  "component---src-pages-q-1-js": () => import("./../../../src/pages/q1.js" /* webpackChunkName: "component---src-pages-q-1-js" */),
  "component---src-pages-q-1-pers-care-js": () => import("./../../../src/pages/q1/pers-care.js" /* webpackChunkName: "component---src-pages-q-1-pers-care-js" */),
  "component---src-pages-refund-policy-js": () => import("./../../../src/pages/refund_policy.js" /* webpackChunkName: "component---src-pages-refund-policy-js" */),
  "component---src-pages-template-lp-dynamic-js": () => import("./../../../src/pages/template-lp.dynamic.js" /* webpackChunkName: "component---src-pages-template-lp-dynamic-js" */),
  "component---src-pages-template-tt-dynamic-js": () => import("./../../../src/pages/template-tt.dynamic.js" /* webpackChunkName: "component---src-pages-template-tt-dynamic-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms_of_service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-testmeas-slider-blog-js": () => import("./../../../src/pages/testmeas_slider_blog.js" /* webpackChunkName: "component---src-pages-testmeas-slider-blog-js" */),
  "component---src-pages-the-coach-reviews-js": () => import("./../../../src/pages/the-coach-reviews.js" /* webpackChunkName: "component---src-pages-the-coach-reviews-js" */),
  "component---src-pages-tt-js": () => import("./../../../src/pages/tt.js" /* webpackChunkName: "component---src-pages-tt-js" */)
}

